@import url(https://fonts.googleapis.com/css?family=Lato|Raleway:400,700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.App {
  background: white; }

.max-width-container {
  max-width: 1192px;
  margin: 0 auto; }

h1 {
  margin: 0;
  font-family: 'Raleway';
  font-weight: normal;
  font-size: 50px; }

img {
  max-width: 100%; }

.section-padding {
  padding: 60px; }
  @media (max-width: 767px) {
    .section-padding {
      padding: 25px; } }

@media (max-width: 767px) {
  .hide-mobile {
    display: none; } }

.flex-section {
  display: flex; }
  .flex-section.center {
    justify-content: space-around;
    align-items: center; }
  @media (max-width: 767px) {
    .flex-section.mobile-column {
      flex-direction: column; } }

a {
  text-transform: uppercase;
  font-family: 'Raleway';
  font-weight: bold; }

.masthead-section {
  position: relative;
  z-index: 1;
  height: 100vh; }
  @media (max-width: 767px) {
    .masthead-section {
      height: 60vh; } }
  .masthead-section__video {
    position: absolute;
    padding: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    object-fit: cover;
    font-family: "object-fit: cover";
    width: 100%;
    height: 100%;
    z-index: -1; }
    @media (max-width: 767px) {
      .masthead-section__video {
        display: none; } }
  .masthead-section__mobile-image {
    position: absolute;
    padding: 0;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    object-fit: cover;
    font-family: "object-fit: cover";
    width: 100%;
    height: 100%;
    z-index: -1;
    display: none; }
    @media (max-width: 767px) {
      .masthead-section__mobile-image {
        display: block; } }
  .masthead-section__text-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-image: radial-gradient(rgba(0, 0, 0, 0.8) 30%, transparent 64%); }
    @media (max-width: 767px) {
      .masthead-section__text-content {
        background-image: radial-gradient(rgba(0, 0, 0, 0.4) 45%, transparent 72%); } }
    .masthead-section__text-content div {
      text-align: center;
      color: white; }
    .masthead-section__text-content h1 {
      color: white; }
    .masthead-section__text-content a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      text-decoration: none;
      -webkit-transition: all .3s ease-in;
      transition: all .3s ease-in; }
      .masthead-section__text-content a svg {
        fill: white;
        margin-left: 8px;
        -webkit-transform: none;
                transform: none;
        -webkit-transition: all .3s ease;
        transition: all .3s ease; }
      .masthead-section__text-content a:hover {
        color: #b31e0e; }
        .masthead-section__text-content a:hover svg {
          fill: #b31e0e;
          -webkit-transform: rotate(90deg);
                  transform: rotate(90deg); }

.release-section__container {
  text-align: center; }
  .release-section__container-image {
    max-height: 500px; }
  .release-section__container-content h2 {
    font-family: 'lato';
    font-size: 28px; }
    @media (max-width: 767px) {
      .release-section__container-content h2 {
        padding: 0 10px; } }
    .release-section__container-content h2 span {
      color: #b31e0e; }

.release-section__streaming ul {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 25px 100px; }
  @media (max-width: 767px) {
    .release-section__streaming ul {
      padding: 0;
      flex-direction: column; } }
  .release-section__streaming ul li {
    list-style: none;
    width: calc(100% / 6); }
    .release-section__streaming ul li .release-link {
      display: block; }
      .release-section__streaming ul li .release-link img {
        width: 100%; }
    @media (max-width: 767px) {
      .release-section__streaming ul li {
        width: 75%;
        max-width: 250px;
        padding: 10px 0; }
        .release-section__streaming ul li:nth-child(3) {
          padding-top: 30px; } }

.bio-section {
  background: #03020ab3; }
  @media (min-width: 768px) {
    .bio-section__container--content-section {
      padding-right: 35px;
      max-width: 45%; } }
  .bio-section__container--content-section p {
    color: white;
    font-family: 'lato';
    line-height: 1.5;
    font-size: 18px; }

.music-section {
  text-align: center; }
  .music-section h2 {
    font-family: 'lato';
    font-size: 28px;
    text-transform: uppercase; }

.footer-section {
  background: black; }
  .footer-section__container {
    display: flex;
    justify-content: space-around; }
  .footer-section__column.image-column {
    width: 33%; }
    .footer-section__column.image-column img {
      max-height: 250px; }
  @media (min-width: 768px) {
    .footer-section__column.link-column {
      padding-left: 50px;
      width: 60%; } }
  .footer-section__column.link-column ul {
    padding: 0 25px;
    display: flex;
    flex-wrap: wrap; }
    .footer-section__column.link-column ul li {
      list-style: none;
      flex-basis: 50%;
      max-width: 50%;
      padding: 10px 0; }
      .footer-section__column.link-column ul li .footer-link {
        line-height: 1;
        display: flex;
        align-items: center;
        color: white;
        text-decoration: none;
        font-size: 14px;
        -webkit-transition: all .3s ease;
        transition: all .3s ease; }
        .footer-section__column.link-column ul li .footer-link img {
          width: 32px;
          margin-right: 10px; }
        .footer-section__column.link-column ul li .footer-link:hover {
          color: #b31e0e; }
      @media (max-width: 767px) {
        .footer-section__column.link-column ul li {
          padding: 12px 0; }
          .footer-section__column.link-column ul li .footer-link img {
            width: 28px; } }

