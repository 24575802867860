.release-section {
  &__container {
    text-align: center;

    &-image {
      max-height: 500px;
    }

    &-content {
      h2 {
        font-family: 'lato';
        font-size: 28px;
        @media(max-width: 767px) {
          padding: 0 10px;
        }
        span {
          color: #b31e0e;
        }
      }
    }
  }
  &__streaming {
    ul {
      display: flex;
      align-items: center;
      justify-content: space-around;
      padding: 25px 100px;
      @media(max-width: 767px) {
        padding: 0;
        flex-direction: column;
      }
      li {
        list-style: none;
        width: calc(100% / 6);

        .release-link {
          display: block;
          img {
            width: 100%;
          }
        }
        @media(max-width: 767px) {
          width: 75%;
          max-width: 250px;
          padding: 10px 0;
          &:nth-child(3) {
            padding-top: 30px;
          }
        }
      }
    }
  }
}