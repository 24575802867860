@import url('https://fonts.googleapis.com/css?family=Lato|Raleway:400,700&display=swap');

.App {
  background: white;
}

@mixin cover-background {
  position: absolute;
  padding: 0;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  margin: auto;
  object-fit: cover;
  font-family: "object-fit: cover";
  width: 100%;
  height: 100%;
  z-index: -1;
}

.max-width-container {
  max-width: 1192px;
  margin: 0 auto;
}

h1 {
  margin: 0;
  font-family: 'Raleway';
  font-weight: normal;
  font-size: 50px;
}

img {
  max-width: 100%;
}

.section-padding {
  padding: 60px;

  @media(max-width: 767px) {
    padding: 25px;
  }
}

.hide-mobile {
  @media(max-width: 767px) {
    display: none;
  }
}

.flex-section {
  display: flex;

  &.center {
    justify-content: space-around;
    align-items: center;
  }

  &.mobile-column {
    @media(max-width: 767px) {
      flex-direction: column;
    }
  }
}

a {
  text-transform: uppercase;
  font-family: 'Raleway';
  font-weight: bold;
}