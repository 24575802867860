.footer-section {
  background: black;

  &__container {
    display: flex;
    justify-content: space-around;
  }

  &__column {
    &.image-column {
      width: 33%;
      img {
        max-height: 250px;
      }
    }

    &.link-column {
      @media(min-width: 768px) {
        padding-left: 50px;
        width: 60%;
      }
      ul {
        padding: 0 25px;
        display: flex;
        flex-wrap: wrap;
        li {
          list-style: none;
          flex-basis: 50%;
          max-width: 50%;
          padding: 10px 0;
          .footer-link {
            line-height: 1;
            display: flex;
            align-items: center;
            color: white;
            text-decoration: none;
            font-size: 14px;
            transition: all .3s ease;
            img {
              width: 32px;
              margin-right: 10px;
            }

            &:hover {
              color: #b31e0e;
            }
          }
          @media(max-width: 767px) {
            padding: 12px 0;
            .footer-link {
              img {
                width: 28px;
              }
            }
          }
        }
      }
    }
  }
}