.masthead-section {
  position: relative;
  z-index: 1;
  height: 100vh;

  @media(max-width: 767px) {
    height: 60vh;
  }

  &__video {
    @include cover-background;

    @media(max-width: 767px) {
      display: none;
    }
  }

  &__mobile-image {
    @include cover-background;
    display: none;

    @media (max-width: 767px) {
      display: block;
    }
  }

  &__text-content {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
    background-image: radial-gradient(rgba(0,0,0,.8) 30%,transparent 64%);

    @media(max-width: 767px) {
      background-image: radial-gradient(rgba(0, 0, 0, 0.4) 45%, transparent 72%);
    }

    div {
      text-align: center;
      color: white;
    }

    h1 {
      color: white;
    }

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      color: white;
      text-decoration: none;
      transition: all .3s ease-in;

      svg {
        fill: white;
        margin-left: 8px;
        transform: none;
        transition: all .3s ease;
      }

      &:hover {
        color: #b31e0e;
        svg {
          fill: #b31e0e;
          transform: rotate(90deg);
        }
      }
    }
  }
}